.body {
  scroll-behavior: smooth;
}

.open-sans-400 {
  font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;
}

.crimson-pro-400 {
  font-family: "Crimson Pro", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.poppins-400 {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.inter-400 {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings:
    "slnt" 0;
}

.teko-400 {
  font-family: "Teko", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.nunito-sans-400 {
  font-family: "Nunito Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings:
    "wdth" 100,
    "YTLC" 500;
}

.hover-underline {
  cursor: pointer;
}

.scroll-content {
  will-change: transform;
}

.hover-underline:hover {
  text-decoration: underline;
  cursor: pointer;
}
.gsap-buttons-container > div {
  cursor: pointer;
}

.StoreCarpet::-webkit-scrollbar {
  display: none;
}

/* Modal.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
}

.modal-overlay.show {
  opacity: 1;
  pointer-events: auto;
}

.modal {
  /* background: white; */
  color: #000;
  padding: 20px;
  border-radius: 8px;
  /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
  opacity: 0;
  transform: scale(1);
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.card {
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}